/* jshint esversion: 6 */
import { Controller } from "@hotwired/stimulus";

// refer: https://medium.com/pragmatic-programmers/animating-turbo-streams-with-animate-css-58fdc93b7814

// usage:
// 1. 全局引入turbo-transition controller, 即可添加所有7种turbo action的入场/出场动画
// <div id="turbo-transition-slot" data-controller="turbo-transition"></div>
// 2. 在需要自定义动画的地方指定data属性
//   <div data-animate-enter='animate-fade-in'>
//   <div data-animate-leave='animate-fade-out'>
// 3. 个别通过其他方式关闭的, 参考modalController#close
export default class extends Controller {
  static targets = [];

  defaultAnimateEnter = 'animate-fade-in-up';
  defaultAnimateLeave = 'animate-fade-out';

  connect() {
    console.log("turbo transition Connected");

    let that = this;

    // 全局绑定, 待重构
    document.addEventListener("turbo:before-stream-render", (event) => {
      console.log("turbo:before-stream-render");
      console.log(event.target.action);
      // event.preventDefault();

      const enterActions = ["append", "prepend", "replace", "update", "before", "after"];
      if (enterActions.includes(event.target.action)) {
        const targetFrame = document.getElementById(event.target.target);
        that.enter(targetFrame, targetFrame.dataset.animateEnter);
      }

      const leaveActions = ["remove"];
      if (leaveActions.includes(event.target.action)) {
        event.preventDefault();
        const targetFrame = document.getElementById(event.target.target);
        that.leave(targetFrame, targetFrame.dataset.animateLeave);
        // if (targetFrame.dataset.animateOut) {
        //   event.preventDefault();
        //   const elementBeingAnimated = targetFrame;
        //   elementBeingAnimated.classList.add(targetFrame.dataset.animateOut);
        //   elementBeingAnimated.addEventListener("animationend", () => {
        //     targetFrame.remove();
        //   });
        // }
      }


    });
  }

  enter(target, animation) {
    let animationClass = animation || this.defaultAnimateEnter;
    this.addAnimation(target, animationClass);
  }

  leave(target, animation) {
    let animationClass = animation || this.defaultAnimateLeave;
    this.addAnimation(target, animationClass);
    target.addEventListener("animationend", () => {
      target.remove();
    });
  }

  // 添加动画, 播放完移除
  addAnimation(element, animation) {
    element.classList.add(animation);
    element.addEventListener("animationend", () => {
      element.classList.remove(animation);
    });
  }
}
