/* jshint esversion: 6 */

import { Controller } from "@hotwired/stimulus";
import Glide from '@glidejs/glide';
import LazyLoad from 'vanilla-lazyload';
import Swal from 'sweetalert2';
import ClipboardJS from 'clipboard';
import $ from 'jquery';

export default class extends Controller {

  initialize() {
  }

  connect() {
    console.log('Global connected');

    this.initMiniProfiler();
    this.initLazyLoad();
    this.initGlide();
    this.initBaiduTongji();
    this.initClarity();

    // turbo cache前清理现场
    document.addEventListener('turbo:before-cache', () => {
      this.stageClean();
    });
  }

  disconnect() {
  }

  stageClean() {
  }

  initMiniProfiler() {
    document.addEventListener('turbo:load', (e) => {
      // 让rack_mini_profile在turbo加载新页面后也能显示
      if (window.MiniProfilerContainer) {
        document.body.appendChild(window.MiniProfilerContainer);
      }
    });
  }

  initGlide() {
    // 先检查页面上是否有glide元素，再初始化
    // 要在lozad前加载，否则lozad延迟加载图片可能有部分无法显示
    const $glideArray = Array.prototype.slice.call(document.querySelectorAll('.glide'), 0);
    if ($glideArray.length === 0) {
      console.log('glide not found');
    } else {
      let glide = new Glide('.glide', {
        type: 'carousel',
        startAt: 1,
        perView: 4,
        focusAt: 'center',
        gap: 20, // 图片间距
        autoplay: 6000,
        hoverpause: true,
        breakpoints: {
          600: { perView: 1 },
          1200: { perView: 3 },
        },
      });
      glide.on('mount.after', function () {
        // Logic fired after mounting
        $('.glide-text').removeClass('is-hidden');
      });
      glide.mount();
    }
  }

  initLazyLoad() {
    window.lazyload = new LazyLoad();
  }

  initBaiduTongji() {
    // push百度统计事件
    if (typeof _hmt === 'undefined') {
      console.log('baidu tongji not loaded');
    } else {
      const $baiduEventLinks = Array.prototype.slice.call(document.querySelectorAll('.baidu_event_link'), 0);
      console.log('click' + $baiduEventLinks.length);

      if ($baiduEventLinks.length > 0) {
        // Add a click event on each of them
        $baiduEventLinks.forEach((el) => {
          el.addEventListener('click', () => {
            console.log('track baidu event');

            const btCategory = el.dataset.btEventCat;
            const btAction = el.dataset.btEventAction;
            const btLabel = el.dataset.btEventLabel; // 可选， '-' 标识无输入
            const btValue = el.dataset.btEventValue; // 仅数值类型
            _hmt.push(['_trackEvent', btCategory, btAction, btLabel, btValue]);
          });
        });
      }
    }
  }

  initClipboard() {
    // 复制到剪贴板
    var btns = document.querySelectorAll('.clipboard_btn');
    var clipboard = new ClipboardJS(btns);
    clipboard.on('success', function (e) {
      console.log(e);
    });
    clipboard.on('error', function (e) {
      console.log(e);
    });
    // end of Clipboard

    // 复制到剪贴板
    var inv_btns = document.querySelectorAll('.btn-invitation');
    var inv_clipboard = new ClipboardJS(inv_btns);
    inv_clipboard.on('success', function (e) {
      console.log(e);
      Swal.fire({
        title: '邀请链接已存入剪贴板\n请将链接分享到网站、社交媒体\n每邀请一位获得一定积分，高分优先注册',
        text: e.text,
        icon: 'success',
        width: 700,
        confirmButtonText: '我知道了',
      });
    });
    inv_clipboard.on('error', function (e) {
      console.log(e);
    });
    // end of Clipboard
  }

  initClarity() {
    // Microsoft Clarity tracker code
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', '96qdjqydy8');
    // end of clarity
  }
}
