/* jshint esversion: 6 */
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["container", "content", "overlay", "close"];

  connect() {
    console.log("modal Connected");

    this.contentChangedObserver();
  }

  open() {
    // console.log("open");
  }

  close() {
    // console.log("close");
    this.addAnimation(this.contentTarget, "animate-fade-out-down");
    this.overlayTarget.classList.add("hidden");
    setTimeout(() => {
      this.contentTarget.innerHTML = '';
    }, 500);
  }

  contentChangedObserver() {
    let that = this;
    // 选择需要观察变动的节点
    const targetNode = this.contentTarget;

    // 观察器的配置（需要观察什么变动）
    const config = { attributes: false, childList: true, subtree: false };

    // 当观察到变动时执行的回调函数
    const callback = function (mutationsList, observer) {
      // 检查content是否有内容
      if (that.contentTarget.innerHTML === "") {
        // 无内容，清理overlay
        that.overlayTarget.classList.add("hidden");
      } else {
        // 有内容，打开overlay
        that.overlayTarget.classList.remove("hidden");
      }
    };

    // 创建一个观察器实例并传入回调函数
    const observer = new MutationObserver(callback);

    // 以上述配置开始观察目标节点
    observer.observe(targetNode, config);
  }

  // 添加动画类, 播放完移除
  addAnimation(element, animation) {
    element.classList.add(animation);
    element.addEventListener("animationend", () => {
      element.classList.remove(animation);
    });
  }

  stageClean() {
    this.close();
  }
}
