/* jshint esversion: 6 */

import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static values = { qs: String, countdownTimer: Number }; // qs: qr_scene_id

  initialize() {}

  connect() {
    console.log("Wechat Qrcode Scene connected");
    this.autoLogin();
  }

  disconnect() {
    clearInterval(this.countdownTimerValue);
  }

  autoLogin() {
    let that = this;

    let countdownTotal = 60; // 2秒1次, 轮询60次
    this.countdownTimerValue = setInterval(() => {
      // that.secondsTarget.innerHTML = countdownTotal;
      if (countdownTotal <= 0) {
        clearInterval(that.countdownTimerValue);
      } else {
        // this.delayJumpContainerTarget.innerHTML = "跳转中...";

        let url = "/scene_login?qs=" + that.qsValue + "&utm_source=qr_scene";
        fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            // msg为'ok'时, 登录成功, 刷新页面
            if (json.msg === 'ok') {
              window.location.reload();
            }
          });
      }

      countdownTotal--;
    }, 2000);
  }
}
